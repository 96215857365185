function calc_timestamps_renders() {
  const differenceInSeconds = Math.floor(
    (window._mochileiros.renderFront -
      window._mochileiros.renderServer * 1000) /
      1000
  );

  return differenceInSeconds;
}

function handlePageLoaded() {
  document.documentElement.classList.add("loaded");
  document.body.classList.add("loaded");
}

async function init() {
  window._mochileiros.renderFront = new Date().getTime();
  const timestampInSeconds = calc_timestamps_renders();

  const timeout =
    timestampInSeconds < 10 && timestampInSeconds > 2
      ? (10 - timestampInSeconds) * 1000
      : 1000;

  setTimeout(handlePageLoaded, timeout);
}

export default {
  init,
};
