const handleIntersectingImage = function (elTarget) {
    elTarget.addEventListener("load", function () {
        elTarget.removeAttribute("data-lazy");
    });
};

const handleIntersectingVideo = function (elTarget) {
    elTarget.querySelectorAll("[data-src]").forEach((el) => {
        el.src = el.getAttribute("data-src");
    });

    elTarget.load();
    elTarget.playbackRate = 0.65;

    elTarget.addEventListener(
        "loadeddata",
        function () {
            elTarget.removeAttribute("data-lazy");
        },
        false
    );
};

const handleObserver = () => {
    const allLazy = document.querySelectorAll("[data-lazy]");

    const elementObserver = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
            if (entry.isIntersecting) {
                const elTarget = entry.target;

                if (elTarget.hasAttribute("data-lazy")) {
                    switch (elTarget.tagName.toLocaleLowerCase()) {
                        case 'div':
                            elTarget.removeAttribute("data-lazy");
                            break;
                        case "video":
                            handleIntersectingVideo(elTarget);
                            break;
                        case "source":
                            elTarget.srcset = elTarget.getAttribute("data-srcset");
                            break;
                        case "img":
                            handleIntersectingImage(elTarget);
                        default:
                            elTarget.src = elTarget.getAttribute("data-src");
                            break;
                    }
                }

                elementObserver.unobserve(elTarget);
            }
        });
    });

    allLazy.forEach(function (el) {
        elementObserver.observe(el, {
            rootMargin: "0 0 -20px 0",
            attributes: false,
            childList: false,
            characterData: false,
        });
    });
};

function init() {
    if ("IntersectionObserver" in window) {
        // utiliza Intersection Observer
        handleObserver();
    }
    /**
    else {
      // utiliza addEventListener
    }
    */
}

export default { init };
