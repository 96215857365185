"use strict";

import pageLoaded from "./partials/controller_pageLoaded";
import intersectionObserver from "./partials/controller_intersectionObserver";

(() => {
  window.addEventListener("DOMContentLoaded", () => {
    window.scroll(0, 0);
  });

  window.addEventListener("load", function () {
    Array.from(this.document.querySelectorAll("[data-src-load]")).forEach(
      (el) => {
        el.src = el.getAttribute("data-src-load");
        el.removeAttribute("data-src-load");
      }
    );

    // kick off the scripts page loaded
    pageLoaded.init();
    // kick off the scripts intersection observer
    intersectionObserver.init();
  });
})();
